<template>
  <v-card class="pa-4" style="min-height: 90vh; max-height: 90vh; overflow-y: hidden">
    <v-card-title>
      <span>{{ $lang.header[type] }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          class="custom-tab-design"
          background-color="transparent"
        >
          <!-- <v-tabs-slider color="accent"></v-tabs-slider> -->

          <v-tab
            :key="0"
            data-cy="tab-entities"
            class="px-7 ml-2"
          >
            {{ $lang.header.entities }}
          </v-tab>
          <v-tab
            :key="1"
            data-cy="tab-statements"
            class="px-7 ml-2"
          >
            {{ $lang.header.statements }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="fill-height pt-3">
          <v-tab-item
            :key="0"
            class="fill-height"
          >
            <div v-if="data && data.data" style="width: 100%; overflow-y: auto" class="py-2 inner-div pr-2">
              <template v-for="(itemEntity, i) in data.data.entities">
                <v-row :key="i + 100" no-gutters>
                  <v-col
                    cols="2"
                    class="pa-2 mr-3 custom-validate-border"
                  >
                    <p :key="i + 500" class="color-secondary d-flex flex-column  ">
                      <span class="color-primary pb-2">{{ itemEntity.name }}</span>
                      {{ $lang.labels.name }}
                    </p>
                  </v-col>
                  <v-col
                    cols="2"
                    class="pa-2 mr-3 custom-validate-border"
                  >
                    <p :key="i + 600" class="color-secondary d-flex flex-column">
                      <span class="color-primary pb-2">{{ itemEntity.version.entity }}</span>
                      {{ $lang.labels.versionEntity }}
                    </p>
                  </v-col>
                  <v-col
                    cols="2"
                    class="pa-2 mr-3 custom-validate-border"
                  >
                    <p :key="i + 700" class="color-secondary d-flex flex-column">
                      <span class="color-primary pb-2">{{ itemEntity.version.persistence ? itemEntity.version.persistence : $lang.labels.nonExistent }}</span>
                      {{ $lang.labels.versionPersistence }}
                    </p>
                  </v-col>

                </v-row>
                <div :key="i + 300" class="custom-validate-table-border mt-5">
                  <h4 :key="i + 700" class=" pa-2 custom-generation-border-bottom">{{ itemEntity.fields.common.length ? 'Fields same both on definition and persistence' : 'No common fields between definition and persistence' }}</h4>
                  <v-data-table
                    v-if="itemEntity.fields.common.length"
                    :key="i + 2000"
                    :headers="headers"
                    :items="itemEntity.fields.common"
                    item-key="id"
                    class="elevation-0 background-transparent px-1"
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template v-slot:item.dataType="{ item }">
                      <div>{{ $lang.status[item.dataType] }}</div>
                    </template>
                    <template v-slot:item.primaryKey="{ item }">
                      <div>{{ item.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.required="{ item }">
                      <div>{{ item.required ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.unique="{ item }">
                      <div>{{ item.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.defaultValue="{ item }">
                      <div>{{ item.defaultValue ? item.defaultValue : $lang.labels.notSet }}</div>
                    </template>
                    <template v-slot:item.sortable="{ item }">
                      <div>{{ item.sortable ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.searchType="{ item }">
                      <div>{{ $lang.status[item.searchType] }}</div>
                    </template>
                    <template v-slot:item.relation="{ item }">
                      <div>{{ item.relation ? item.relation.entityName : $lang.labels.no }}</div>
                    </template>
                  </v-data-table>
                </div>

                <div :key="i + 400" class="custom-validate-table-border mt-5">
                  <h4 :key="i + 1200" class="pa-2 custom-generation-border-bottom">{{ itemEntity.fields.differences.length ? 'Fields with differences between definition and persistence (differences marked in red)' : 'No fields with differences between definition and persistence' }} </h4>
                  <v-data-table
                    v-if="itemEntity.fields.differences.length"
                    :key="i + 5000"
                    :headers="headers"
                    :items="itemEntity.fields.differences"
                    item-key="id"
                    class="elevation-0 background-transparent px-1"
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template v-slot:item.dataType="{ item }">
                      <div v-if="item.entity.dataType === item.persistence.dataType">{{ $lang.status[item.entity.dataType] }}</div>
                      <div v-else class="color-error">
                        <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ $lang.status[item.entity.dataType] }}</div>
                        <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ $lang.status[item.persistence.dataType] }}</div>
                      </div>
                    </template>
                    <template v-slot:item.primaryKey="{ item }">
                      <div v-if="item.entity.primaryKey === item.persistence.primaryKey">{{ item.entity.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                      <div v-else class="color-error">
                        <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                        <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                      </div>
                    </template>
                    <template v-slot:item.required="{ item }">
                      <div v-if="item.entity.required === item.persistence.required">{{ item.entity.required ? $lang.labels.yes : $lang.labels.no }}</div>
                      <div v-else class="color-error">
                        <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.required ? $lang.labels.yes : $lang.labels.no }}</div>
                        <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.required ? $lang.labels.yes : $lang.labels.no }}</div>
                      </div>
                    </template>
                    <template v-slot:item.unique="{ item }">
                      <div v-if="item.entity.unique === item.persistence.unique">{{ item.entity.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                      <div v-else class="color-error">
                        <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                        <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                      </div>
                    </template>
                    <template v-slot:item.defaultValue="{ item }">
                      <div v-if="item.entity.defaultValue === item.persistence.defaultValue">{{ item.entity.defaultValue }}</div>
                      <div v-else class="color-error">
                        <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.defaultValue ? item.entity.defaultValue : $lang.labels.notSet }}</div>
                        <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.unique ? item.persistence.defaultValue : $lang.labels.notSet }}</div>
                      </div>
                    </template>
                    <template v-slot:item.searchType="{ item }">
                      <div v-if="item.entity.searchType === item.persistence.searchType">{{ $lang.status[item.entity.searchType] }}</div>
                      <div v-else class="color-error">
                        <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ $lang.status[item.entity.searchType] }}</div>
                        <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ $lang.status[item.persistence.searchType] }}</div>
                      </div>
                    </template>
                    <template v-slot:item.sortable="{ item }">
                      <div v-if="item.entity.sortable === item.persistence.sortable">{{ item.entity.sortable ? $lang.labels.yes : $lang.labels.no }}</div>
                      <div v-else class="color-error">
                        <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.sortable ? $lang.labels.yes : $lang.labels.no }}</div>
                        <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.sortable ? $lang.labels.yes : $lang.labels.no }}</div>
                      </div>
                    </template>
                    <template v-slot:item.relation="{ item }">
                      <div v-if="item.entity.relation === item.persistence.relation">{{ item.entity.relation ? item.entity.relation.entityName : $lang.labels.no }}</div>
                      <div v-else class="color-error">
                        <div><span class="color-secondary">{{ $lang.header.entity }}:</span> {{ item.entity.relation ? item.entity.relation.entityName : $lang.labels.no }}</div>
                        <div><span class="color-secondary">{{ $lang.header.persistence }}:</span> {{ item.persistence.relation ? item.persistence.relation.entityName : $lang.labels.no }}</div>
                      </div>
                    </template>
                  </v-data-table>
                </div>

                <div :key="i + 3400" class="custom-validate-table-border mt-5">
                  <h4 :key="i + 1500" class="pa-2 custom-generation-border-bottom">
                    {{ itemEntity.fields.entity.length ? 'Fields that exists in definition but not in persistence (these fields will be added on Generate action)' : 'No fields that exists in definition but not in persistence' }}
                  </h4>

                  <!-- Show message with icon if there are no fields -->
                  <div
                    v-if="!itemEntity.fields.entity.length"
                    class="d-flex align-center justify-center ma-2  pa-5 custom-validate-table-border"
                  >
                    <!-- Display info icon here, using Vuetify's v-icon -->
                    <v-icon
                      large
                      outlined
                      class="mr-2"
                      color="warning"
                    >mdi-alert-circle</v-icon> <!-- Replace 'error' with 'info' if needed -->
                    <span>{{ $lang.hints.noEntityFieldsMessage }}</span> <!-- Adjust message according to your language config -->
                  </div>

                  <!-- Show v-data-table if there are fields -->
                  <v-data-table
                    v-if="itemEntity.fields.entity.length"
                    :key="i + 2400"
                    :headers="headers"
                    :items="itemEntity.fields.entity"
                    item-key="id"
                    class="elevation-0 background-transparent"
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <!-- Other table templates go here -->
                    <template v-slot:item.dataType="{ item }">
                      <div>{{ $lang.status[item.dataType] }}</div>
                    </template>
                    <template v-slot:item.primaryKey="{ item }">
                      <div>{{ item.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.required="{ item }">
                      <div>{{ item.required ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.unique="{ item }">
                      <div>{{ item.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.defaultValue="{ item }">
                      <div>{{ item.defaultValue ? item.defaultValue : $lang.labels.notSet }}</div>
                    </template>
                    <template v-slot:item.sortable="{ item }">
                      <div>{{ item.sortable ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.searchType="{ item }">
                      <div>{{ $lang.status[item.searchType] }}</div>
                    </template>
                    <template v-slot:item.relation="{ item }">
                      <div>{{ item.relation ? item.relation.entityName : $lang.labels.no }}</div>
                    </template>
                  </v-data-table>
                </div>

                <div :key="i + 500" class="custom-validate-table-border mt-5">
                  <h4 :key="i + 1800" class="pa-2 custom-generation-border-bottom">
                    {{ itemEntity.fields.persistence.length ? 'Fields that exists in persistence but not in definition (data in these fields will be lost on Generate action!)' : 'No fields that exists in persistence but not in definition' }}
                  </h4>

                  <!-- Show message with icon if there are no fields -->
                  <div
                    v-if="!itemEntity.fields.persistence.length"
                    class="d-flex align-center justify-center ma-2  pa-5 custom-validate-table-border"
                  >
                    <!-- Display info icon here, using Vuetify's v-icon -->
                    <v-icon
                      large
                      outlined
                      class="mr-2"
                      color="warning"
                    >mdi-alert-circle</v-icon> <!-- Replace 'error' with 'info' if needed -->
                    <span>{{ $lang.hints.noPersistenceFieldsMessage }}</span> <!-- Adjust message according to your language config -->
                  </div>
                  <v-data-table
                    v-if="itemEntity.fields.persistence.length"
                    :key="i + 2700"
                    :headers="headers"
                    :items="itemEntity.fields.persistence"
                    item-key="id"
                    class="elevation-0 background-transparent"
                    hide-default-footer
                    :items-per-page="-1"
                  >
                    <template v-slot:item.dataType="{ item }">
                      <div>{{ $lang.status[item.dataType] }}</div>
                    </template>
                    <template v-slot:item.unique="{ item }">
                      <div>{{ item.unique ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.defaultValue="{ item }">
                      <div>{{ item.defaultValue ? item.defaultValue : $lang.labels.notSet }}</div>
                    </template>
                    <template v-slot:item.primaryKey="{ item }">
                      <div>{{ item.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.searchType="{ item }">
                      <div>{{ $lang.status[item.searchType] }}</div>
                    </template>
                    <template v-slot:item.sortable="{ item }">
                      <div>{{ item.sortable ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.required="{ item }">
                      <div>{{ item.required ? $lang.labels.yes : $lang.labels.no }}</div>
                    </template>
                    <template v-slot:item.relation="{ item }">
                      <div>{{ item.relation ? item.relation.entityName : $lang.labels.no }}</div>
                    </template>
                  </v-data-table>
                </div>
              </template>
            </div>
            <div v-else class="fill-height d-flex justify-center align-center">
              <p>No data to show</p>
            </div>
          </v-tab-item>
          <v-tab-item
            :key="1"
            class="fill-height"
          >
            <div v-if="data && data.data" style="width: 100%; overflow-y: auto" class="py-2 inner-div">
              <template v-for="(item, i) in data.data.statements">
                <p :key="i" class="color-secondary"><span class="color-primary">{{ i + 1 }}</span>: {{ item }}</p>
              </template>
            </div>
            <div v-else class="fill-height d-flex justify-center align-center">
              <p>No data to show</p>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      default: 'VALIDATE'
    },
    errorOrSuccess: {
      type: String,
      default: 'SUCCESS'
    },
    data: {
      type: Object,
      default: () => null
    },
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tab: 0,
      headers: [
        { text: this.$lang.labels.name, value: 'name', sortable: false },
        { text: this.$lang.labels.dataType, value: 'dataType', sortable: false },
        { text: this.$lang.labels.primaryKey, value: 'primaryKey', sortable: false },
        { text: this.$lang.labels.required, value: 'required', sortable: false },
        { text: this.$lang.labels.unique, value: 'unique', sortable: false },
        { text: this.$lang.labels.defaultValue, value: 'defaultValue', sortable: false },
        { text: this.$lang.labels.sortable, value: 'sortable', sortable: false },
        { text: this.$lang.labels.searchType, value: 'searchType', sortable: false },
        { text: this.$lang.labels.relation, value: 'relation', sortable: false }
      ]
    }
  },
  created() {
    if (this.errorOrSuccess === 'SUCCESS' && this.message) {
      this.addSnackbar({
        message: this.message,
        timeout: 5000,
        color: 'success'
      })
    } else if (this.message) {
      this.addSnackbar({
        message: this.message,
        timeout: 8000,
        color: 'error'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar'])

  }
}
</script>
<style lang="scss">
.inner-div {
  max-height: calc(90vh - 150px) !important;
}
</style>
