<template>
  <v-card class="pa-2 fill-height fill-width custom-bg" flat>
    <v-row wrap no-gutters >
      <v-col v-if="(!valid || !userCanEdit || !isEdit || isDirty) && !lock" cols="12" class="mb-2 ">
        <v-alert color="info">{{ $lang.hints.saveToShow }}</v-alert>
      </v-col>
      <v-col cols="12" md="6" class="pr-md-2">
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          class="py-2 pl-2 custom-generation-border"
        >
          <!-- Text Column on the Left -->
          <v-col cols="auto" >
            <h4 class="mb-0">{{ $lang.labels.persistenceNew }}</h4>
          </v-col>

          <!-- Buttons Column on the Right -->
          <v-col cols="9" class="d-flex pr-2 justify-end">
            <v-btn
              outlined
              :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty"
              color="info"
              :loading="validateLoading"
              class="color-black button-default-width"
              data-cy="entity-validate-persistence"
              @click="validatePersistence()"
            >
              <v-icon
                left
                dark
              >
                mdi-check-circle
              </v-icon>
              {{ $lang.actions.validate }}
            </v-btn>

            <v-btn
              outlined
              :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty"
              color="warning"
              :loading="generateLoading"
              class="button-default-width ml-2 color-black"
              data-cy="entity-generate-persistence"
              @click="generatePersistence()"
            >
              <v-icon
                left
                dark
              >
                mdi-autorenew
              </v-icon>
              {{ $lang.actions.generate }}
            </v-btn>

            <action-button-with-confirmation
              v-if="isEdit"
              :action-text="$lang.actions.areYouSureYouWantToDelete"
              :action-text-suffix="''"
              :title="$lang.actions.delete"
              type="persistence"
              :is-disabled="!userCanDelete"
              :button-text="''"
              :button-color="'error'"
              :data-cy="'persistence-delete'"
              :forced-option="false"
              :trigger-force-logic="triggerForceLogicPersistence"
              :simple-error="simpleError"
              :success-persistence-messages-array="successPersistenceMessagesArray"
              class="ml-2"
              outlined
              :delete-success="deleteSuccess"
              :currently-open-delete-action="currentlyOpenDeleteAction"
              :delete-instance="2"
              @submit="deletePersistence()"
              @closeDialog="resetValidateGenerateDelete($event)"
              @closeAfterDelete="resetValidateGenerateDelete($event)"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="pl-md-2 mt-2 mt-md-0">
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          class="py-2 pl-2 custom-generation-border"
        >
          <!-- Text Column on the Left -->
          <v-col cols="auto" >
            <h4 class="mb-0">{{ $lang.labels.persistenceUpdate }}</h4>
          </v-col>

          <!-- Buttons Column on the Right -->
          <v-col cols="9" class="d-flex pr-2 justify-end">
            <v-btn
              outlined
              :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty"
              color="info"
              :loading="validateLoadingUpdate"
              class="color-black "
              data-cy="entity-validate-persistence"
              @click="validatePersistenceUpdate()"
            >
              <v-icon
                left
                dark
              >
                mdi-check-circle
              </v-icon>
              {{ $lang.actions.validate }}
            </v-btn>

            <v-btn
              outlined
              :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty"
              color="warning"
              :loading="generateLoadingUpdate"
              class="ml-2 color-black"
              data-cy="entity-generate-persistence"
              @click="generatePersistenceUpdate()"
            >
              <v-icon
                left
                dark
              >
                mdi-autorenew
              </v-icon>
              {{ $lang.actions.generate }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="mt-6 custom-generation-border"
      >
        <div class="d-flex flex-column custom-generation-border-bottom pa-3"> <!-- Make the layout vertical -->
          <h2 >{{ $lang.header.resource }}</h2>
        </div>
        <v-row no-gutters>

          <v-col
            cols="12"
            md="6"
            class="pa-3"
          >
            <v-row no-gutters style="min-height: 370px;">
              <v-col
                cols="12"
              >
                <!-- Resource Type section -->
                <div class="d-flex flex-column "> <!-- Make the layout vertical -->
                  <h4 class="pa-2">{{ $lang.labels.type }}</h4>
                </div>

                <div class="d-flex flex-column">
                  <v-checkbox
                    v-model="selectAllResourceTypes"
                    data-cy="entity-resource-type-select-all"
                    label="All"
                    class="mt-3 pl-2"
                    dense
                    @change="selectAllResourceTypes ? selectedResourceTypes = [...resourceTypes] : selectedResourceTypes = []"
                  ></v-checkbox>
                </div>

                <div class="d-flex flex-column px-2">
                  <v-divider class="mb-1" />
                </div>

                <!-- Resource Type checkboxes -->
                <template
                  v-for="(item, index) in formattedResourceTypes"
                >
                  <v-checkbox
                    :key="index"
                    v-model="selectedResourceTypes"
                    class="pl-2 pt-2"
                    :label="item.text"
                    :value="item.value"
                    :disabled="!userCanEdit"
                    dense
                    data-cy="entity-resource-type"
                  ></v-checkbox>
                </template>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="pa-3"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <!-- Resource Action section -->
                <div class="d-flex flex-column">
                  <h4 class="pa-2">{{ $lang.labels.action }}</h4>
                </div>

                <div class="d-flex flex-column">
                  <v-checkbox
                    v-model="selectAllResourceActions"
                    class="pl-2 mt-3"
                    data-cy="entity-resource-action-select-all"
                    label="All"
                    dense
                    @change="selectAllResourceActions ? selectedResourceActions = [...resourceActions] : selectedResourceActions = []"
                  ></v-checkbox>
                </div>

                <div class="d-flex flex-column px-2">
                  <v-divider class="mb-1" />
                </div>

                <!-- Resource Action checkboxes -->
                <template v-for="(item, index) in formattedResourceActions">
                  <v-checkbox
                    :key="index"
                    v-model="selectedResourceActions"
                    class="pl-2 pt-2"
                    :label="item.text"
                    :value="item.value"
                    :disabled="!userCanEdit"
                    dense
                    data-cy="entity-resource-action"
                  ></v-checkbox>
                </template>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="pa-3">
            <v-row no-gutters>

              <action-button-with-confirmation
                v-if="isEdit"
                :action-text="$lang.actions.areYouSureYouWantToDelete"
                :action-text-suffix="''"
                :title="$lang.actions.delete"
                type="resources"
                :is-disabled="!userCanDelete"
                :button-text="$lang.actions.delete"
                :button-color="'error'"
                :data-cy="'resources-delete'"
                :forced-option="true"
                outlined
                :trigger-force-logic="triggerForceLogicResources"
                :success-resources-messages-array="successResourcesMessagesArray"
                :currently-open-delete-action="currentlyOpenDeleteAction"
                :delete-instance="3"
                :delete-success="deleteSuccess"
                @submit="deleteResources($event)"
                @closeDialog="resetValidateGenerateDelete($event)"
                @closeAfterDelete="resetValidateGenerateDelete($event)"
              />
              <v-col class="d-flex justify-end" >
                <v-btn
                  outlined
                  :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty || (selectedResourceActions.length < 1 || selectedResourceTypes.length < 1)"
                  color="info"
                  :loading="resourceValidateLoading"
                  class="button-default-width color-black"
                  data-cy="entity-validate-resource"
                  @click="resourceValidateFunction()"
                >
                  <v-icon
                    left
                    dark
                  >
                    mdi-check-circle
                  </v-icon>
                  {{ $lang.actions.validate }}
                </v-btn>
                <v-btn
                  outlined
                  :disabled="!valid || lock || !userCanEdit || !isEdit || isDirty || (selectedResourceActions.length < 1 || selectedResourceTypes.length < 1)"
                  color="warning"
                  :loading="resourceGenerateLoading"
                  class="ml-2 color-black"
                  data-cy="entity-generate-resource"
                  @click="resourceGenerateFunction()"
                >
                  <v-icon
                    left
                    dark
                  >
                    mdi-autorenew
                  </v-icon>
                  {{ $lang.actions.generate }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-if="showValidateGenerateModal" v-model="showValidateGenerateModal" max-width="80%">
      <validate-generate-result-modal
        :type="validateGenerateType"
        :error-or-success="errorOrSuccess"
        :data="validateGenerateData"
        :message="validateGenerateMessage"
        @closeDialog="resetValidateGenerateModal()"
      />
    </v-dialog>
    <v-dialog v-if="showResourceValidateGenerateModal" v-model="showResourceValidateGenerateModal" max-width="80%">
      <resource-validate-generate-result-modal
        :type="validateGenerateType"
        :error-or-success="errorOrSuccess"
        :data="validateGenerateData"
        :message="validateGenerateMessage"
        @closeDialog="resetValidateGenerateModal()"
        @openResource="openResource($event)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import {
  entityPersistenceGenerateUsingPOST as generatePersistence,
  entityPersistenceValidateUsingGET as validatePersistence,
  entityResourcesValidateUsingPOST as validateResources,
  entityResourcesGenerateUsingPOST as generateResources,
  entityPersistenceValidateUpdateUsingGET as validateUpdatePersistence,
  entityPersistenceExecuteUpdateUsingPUT as executeUpdatePersistence
} from '@/utils/api'
import ValidateGenerateResultModal from '@/pages/entities/ValidateGenerateResultModal.vue'
import ResourceValidateGenerateResultModal from '@/pages/entities/ResourcesValidateGenerateResultModal.vue'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import { definitions } from '@/utils/definitions'
import { resourcesTypes } from '../../../utils/constants'

export default {
  name: 'GenerationCard',
  components: {
    ValidateGenerateResultModal,
    ResourceValidateGenerateResultModal,
    ActionButtonWithConfirmation
  },
  props: {
    entityId: {
      type: Number,
      default: null
    },
    valid: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    },
    userCanEdit: {
      type: Boolean,
      default: false
    },
    userCanDelete: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    simpleError: {
      type: String,
      default: ''
    },
    successPersistenceMessagesArray: {
      type: Array,
      default: () => []
    },
    successResourcesMessagesArray: {
      type: Array,
      default: () => []
    },
    triggerForceLogicPersistence: {
      type: Boolean,
      default: false
    },
    triggerForceLogicResources: {
      type: Boolean,
      default: false
    },
    currentlyOpenDeleteAction: {
      type: Number,
      default: 0
    },
    deleteSuccess: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      resourcesTypes,
      selectAllResourceTypes: false,
      selectAllResourceActions: false,
      showValidateGenerateModal: false,
      showResourceValidateGenerateModal: false,
      validateLoading: false,
      generateLoading: false,
      validateLoadingUpdate: false,
      generateLoadingUpdate: false,
      errorOrSuccess: 'SUCCESS',
      validateGenerateType: 'VALIDATE',
      validateGenerateData: null,
      validateGenerateMessage: '',
      err: '',
      selectedResourceTypes: [],
      selectedResourceActions: [],
      resourceValidateLoading: false,
      resourceGenerateLoading: false,
      resourceTypes: ['VALIDATION_RULE', 'PROCESS', 'TRIGGER_REST'], // excluding messaging till be implemented; after implementation take all types from here: definitions.ResourceAction.properties.resourceType.enum
      resourceActions: definitions.ResourceAction.properties.actionType.enum
    }
  },
  computed: {
    btnNameTypes() {
      return this.selectedResourceTypes && this.selectedResourceTypes.length < 3 ? this.$lang.actions.selectAll : this.$lang.actions.selectNone
    },
    btnNameActions() {
      return this.selectedResourceActions && this.selectedResourceActions.length < 3 ? this.$lang.actions.selectAll : this.$lang.actions.selectNone
    },
    selectedResources() {
      return this.selectedResourceTypes.map((x) =>
        this.selectedResourceActions.map((y) => ({
          actionType: y,
          resourceType: x
        })
        )).flat()
    },
    formattedResourceTypes() {
      return this.resourceTypes.map((item) => ({
        text: this.$lang.status[item],
        value: item
      }
      ))
    },
    formattedResourceActions() {
      return this.resourceActions.map((item) => ({
        text: this.$lang.status[item],
        value: item
      }))
    }
  },
  methods: {
    openResource(resourceTypeObject) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/${this.resourcesTypes[resourceTypeObject.resource.resourceType]}/${resourceTypeObject.resource[resourceTypeObject.type].id}`, '_blank')
    },
    resetValidateGenerateModal() {
      this.showValidateGenerateModal = false
      this.showResourceValidateGenerateModal = false
      this.errorOrSuccess = 'SUCCESS'
      this.validateGenerateType = 'VALIDATE'
      this.validateGenerateData = null
      this.validateGenerateMessage = ''
    },
    handleResponse(res, type, loading, showModal) {
      this.validateGenerateType = type
      if (res.status !== 200) {
        if (res.response.status === 403) {
          this.err = this.$lang.errors.noPermission
        } else if (/^4\d{2}$/.test(res.response.status)) { // other 4xx status codes
          this.validateGenerateMessage = res.response.data.statusText
        } else {
          this.validateGenerateMessage = res.response.statusText
        }
        this.validateGenerateData = res.response.data
        this.errorOrSuccess = 'ERROR'
      } else {
        this.validateGenerateData = res.data
        this.validateGenerateMessage = res.statusText
        this.errorOrSuccess = 'SUCCESS'
      }
      this[loading] = false
      this[showModal] = true
    },
    handleError(loading, errorType) {
      this[loading] = false
      this.err = this.$lang.errors[errorType]
      setTimeout(() => this.err = '', 5000)
    },
    initializePersistenceHandling(loading) {
      this.resetValidateGenerateModal()
      this.err = ''
      this[loading] = true
    },
    validatePersistence() {
      this.initializePersistenceHandling('validateLoading')
      validatePersistence({ id: this.entityId })
        .then((res) =>
          this.handleResponse(res, 'VALIDATE', 'validateLoading', 'showValidateGenerateModal'))
        .catch(() =>
          this.handleError('validateLoading', 'entityValidation'))
    },
    generatePersistence() {
      this.initializePersistenceHandling('generateLoading')
      generatePersistence({ id: this.entityId })
        .then((res) =>
          this.handleResponse(res, 'GENERATE', 'generateLoading', 'showValidateGenerateModal'))
        .catch(() =>
          this.handleError('generateLoading', 'entityGenerate')
        )
    },
    validatePersistenceUpdate() {
      this.initializePersistenceHandling('validateLoadingUpdate')
      validateUpdatePersistence({ id: this.entityId })
        .then((res) =>
          this.handleResponse(res, 'VALIDATE', 'validateLoadingUpdate', 'showValidateGenerateModal'))
        .catch(() =>
          this.handleError('validateLoading', 'entityValidation'))
    },
    generatePersistenceUpdate() {
      this.initializePersistenceHandling('generateLoadingUpdate')
      executeUpdatePersistence({ id: this.entityId })
        .then((res) =>
          this.handleResponse(res, 'GENERATE', 'generateLoadingUpdate', 'showValidateGenerateModal'))
        .catch(() =>
          this.handleError('generateLoading', 'entityGenerate')
        )
    },
    resourceValidateFunction() {
      this.err = ''
      this.resourceValidateLoading = true
      validateResources({ id: this.entityId, body: { resources: this.selectedResources } })
        .then((res) =>
          this.handleResponse(res, 'VALIDATE', 'resourceValidateLoading', 'showResourceValidateGenerateModal')
        )
        .catch(() =>
          this.handleError('resourceValidateLoading', 'entityValidation'))
    },
    resourceGenerateFunction() {
      this.err = ''
      this.resourceGenerateLoading = true
      generateResources({ id: this.entityId, body: { resources: this.selectedResources } })
        .then((res) =>
          this.handleResponse(res, 'GENERATE', 'resourceGenerateLoading', 'showResourceValidateGenerateModal'))
        .catch(() =>
          this.handleError('resourceGenerateLoading', 'entityGenerate'))
    },
    deletePersistence() {
      this.$emit('deletePersistenceFunct', this.entityId)
    },
    deleteResources(isForceDelete) {
      this.$emit('deleteResourcesFunct', isForceDelete, this.entityId)
    },
    resetValidateGenerateDelete(e) {
      if (this.currentlyOpenDeleteAction !== 1) {
        this.$emit('resetValidateGenerateDelete', e)
      }
    }
  }
}
</script>
