<template>
  <v-container fluid class=" custom-container-details">
    <div>
      <v-row wrap no-gutters >
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          style="display: flex; flex-direction: column"
        >
          <v-tabs
            v-model="tab"
            background-color="transparent"
            class="custom-tab-design"
          >

            <v-tab
              :key="0"
              data-cy="tab-details"
              class="ml-2"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-advanced"
              class="ml-2"
            >
              {{ $lang.labels.advanced }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-generation"
              class="ml-2"
            >
              {{ $lang.labels.generation }}
            </v-tab>
            <v-tab
              :key="3"
              data-cy="tab-relations"
              class="ml-2"
            >
              {{ $lang.labels.relations }}
            </v-tab>
            <v-tab
              :key="4"
              data-cy="tab-diagram"
              class="ml-2"
            >
              {{ $lang.labels.diagram }}
            </v-tab>
            <v-tab
              :key="5"
              data-cy="tab-resources"
              class="ml-2"
            >
              {{ $lang.labels.resources }}
            </v-tab>
            <v-tab
              :key="6"
              data-cy="tab-permissions"
              class="ml-2"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="7"
              data-cy="tab-history"
              class="ml-2"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            class=" custom-bg pt-6 "
          >
            <v-tab-item
              :key="0"
              class="fill-height "
            >
              <v-card class="pa-1 fill-height custom-bg" >
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="py-1">
                    <v-col
                      cols="12"
                      sm="11"
                      class="pr-sm-1"
                    >
                      <div class="d-inline-flex max-width">
                        <v-text-field
                          v-model="entity.name"
                          outlined
                          dense
                          :label="$lang.labels.name"
                          required
                          class="required-asterisk copy-name-icon"
                          :rules="[v => !!v || $lang.labels.required, v => (v && v.length > 1 && v.length <= 64) || $lang.errors.nameTooShortOrLong64, v=> /^[A-Za-z0-9_]+$/g.test(v) || 'Only letters, numbers and underscore!']"
                          :persistent-hint="isEdit"
                          :hint="formatHint"
                          :readonly="!userCanEdit"
                          data-cy="entity-name"
                          append-icon="mdi-vector-combine"
                          @click:append="copyName()"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col v-if="isEdit" cols="1" class="pl-sm-1 text-right pb-2  ">
                      <v-btn
                        outlined
                        class="pl-3 button-default-width"
                        color="primary"
                        :disabled="!userCanEdit"
                        data-cy="entity-status"
                        @click="changeStatus()"
                      >
                        <v-icon
                          left
                          dark
                        >
                          {{ entity.status === 'ACTIVE' ? 'mdi-toggle-switch-off ' : 'mdi-toggle-switch' }}
                        </v-icon>
                        {{ entity.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="2"
                    >
                      <v-select
                        v-model="entityType"
                        outlined
                        dense
                        :items="formattedEntityTypes"
                        :label="$lang.labels.entityType"
                        required
                        hide-details
                        class="required-asterisk pr-1"
                        :rules="[v => !!v || $lang.labels.required]"
                        :readonly="!userCanEdit"
                        data-cy="entity-type"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="10"
                    >
                      <div class="d-flex align-center">
                        <v-autocomplete
                          v-model="entity.credentialId"
                          data-cy="entity-credentialId"
                          outlined
                          dense
                          :items="credentials"
                          :loading="isLoadingCredentials"
                          :search-input.sync="searchCredentials"
                          clearable
                          hide-no-data
                          hide-details
                          item-text="name"
                          item-value="id"
                          :label="$lang.labels.credential"
                          :placeholder="$lang.actions.startTyping"
                          prepend-inner-icon="mdi-cloud-search-outline"
                          :readonly="!userCanEdit"
                          required
                          class="required-asterisk flex-grow-1 max-width copy-name-icon pl-1"
                          :rules="[v => !!v || $lang.labels.required]"
                          append-icon="mdi-vector-combine"
                          @click:append="copyCredential()"
                        ></v-autocomplete>
                        <v-btn
                          icon
                          class="ml-1"
                          color="primary"
                          :disabled="!entity.credentialId"
                          data-cy="entity-credential-search"
                          @click="searchCredentialsFunction(searchCredentials)"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                        <v-btn
                          outlined
                          class="ml-1"
                          color="primary"
                          :disabled="!entity.credentialId"
                          data-cy="entity-credential-open"
                          @click="openCredential()"
                        >
                          {{ $lang.actions.openTemplate }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-col
                    cols="12"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="text-right my-2 d-flex justify-space-between"
                      >
                        <div class="d-flex align-center py-1">
                          <h3>{{ $lang.labels.fields }}</h3>
                          <v-btn
                            color="primary"
                            outlined
                            class="ml-2"
                            :disabled="!userCanEdit"
                            data-cy="entity-field-add"
                            @click="addNewField()"
                          >

                            <v-icon small>mdi-plus</v-icon>
                            <span class="color-primary pl-1">{{ $lang.actions.addField }}</span>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col
                        v-if="entity.fields.length < 1"
                        cols="12"
                      >
                        <v-col
                          cols="12"
                        >

                          <v-alert
                            class="d-flex justify-center align-center pb-3"
                            data-cy="entity-fields-hint"
                          >
                            <v-icon
                              class="d-flex justify-center align-center pb-3"
                              color="accent"
                            >
                              mdi-alert-circle
                            </v-icon>

                            {{ $lang.hints.addAtLeastOneField }}
                          </v-alert>
                        </v-col>
                      </v-col>
                      <v-col
                        :key="tempKey"
                        cols="12"
                      >
                        <template v-for="(field, index) in entity.fields">
                          <entity-field
                            :key="index"
                            :field-data="field"
                            :index="index"
                            :user-can-edit="userCanEdit"
                            :is-movable-up="entity.fields.length > 1 && index !== 0"
                            :is-movable-down="entity.fields.length > 1 && index !== entity.fields.length - 1"
                            :entity-type="entityType"
                            :primary-key="field.primaryKey"
                            :usages="entity.usages"
                            @removeField="deleteField(index)"
                            @updateField="updateEntityField(index, $event)"
                            @moveField="moveField(index, $event)"
                            @check-duplicates="checkDuplicateFieldNames(entity.fields)"
                          ></entity-field>
                        </template>
                        <div class="d-flex align-center justify-space-between pb-4">
                          <div class="d-flex align-center">
                            <v-btn
                              color="primary"
                              outlined
                              class=""
                              :disabled="!userCanEdit"
                              data-cy="entity-field-add"
                              @click="addNewField()"
                            >

                              <v-icon small>mdi-plus</v-icon>
                              <span class="color-primary pl-1">{{ $lang.actions.addField }}</span>
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-row no-gutters wrap justify="space-between">
                    <v-divider />
                  </v-row>
                  <v-row no-gutters wrap justify="space-between" class="mt-2">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center ">
                        <div class="pt-1 pb-1">
                          <v-btn
                            color="primary"
                            outlined
                            class="button-default-width "
                            :to="{ name: 'entities', params: { lang: $lang.current_lang } }"
                            data-cy="entity-back"
                          >
                            <v-icon
                              right
                              dark
                              class="ma-0"
                            >
                              mdi-arrow-left
                            </v-icon>
                            <span class="ml-1">{{ $lang.actions.back }}</span>
                          </v-btn>
                        </div>
                        <div class="d-inline-flex align-center">
                          <v-btn
                            v-if="browseDataAccess"
                            outlined
                            color="primary"
                            class="ml-2"
                            data-cy="entity-browse-data"
                            :to="{ name: 'browseData', params: { lang: $lang.current_lang }, query: { entity: entity.name }}"
                          >
                            <v-icon
                              right
                              dark
                              class="ma-0"
                            >
                              mdi-database-search-outline
                            </v-icon>
                            <span class="ml-1">{{ $lang.routes.browseData }}</span>
                          </v-btn>
                          <action-button-with-confirmation
                            v-if="isEdit"
                            :action-text="$lang.actions.areYouSureYouWantToDelete"
                            :action-text-suffix="entity.name"
                            :title="$lang.actions.delete"
                            type="entity"
                            :is-disabled="!userCanDelete"
                            :is-loading="isDeleteDialogLoading"
                            :button-text="$lang.actions.delete"
                            :button-color="'error'"
                            :data-cy="'entity-delete'"
                            :forced-option="true"
                            :trigger-force-logic="triggerForceLogic"
                            :regular-delete-errors-usages="regularDeleteErrorsUsages"
                            :additional-component="entityDeleteOptionsConfig"
                            class="ml-2"
                            outlined
                            :delete-success="deleteSuccess"
                            :currently-open-delete-action="currentlyOpenDeleteAction"
                            :delete-instance="1"
                            :simple-error="simpleError"
                            @submit="deleteEntityFunct($event)"
                            @closeDialog="''"
                            @closeAfterDelete="$router.push({ name: 'entities' })"
                            @passData="passDeleteOptions($event)"
                          />
                          <v-dialog v-model="deleteModal" max-width="75%" height="80vh" @click:outside="closeDeleteModal">
                            <v-card class="pa-2" flat>
                              <v-card-text>
                                <p style="font-size: 16px;">{{ $lang.errors.listOfUsedEntities }}</p>
                                <v-simple-table>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th>Entity Name</th>
                                        <th>Field Name & Relation Type</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(usage, index) in entity.usages" :key="usage.entityId">
                                        <td>{{ entityUsageNames[index] }}</td>
                                        <td>{{ usage.field }}<br/><small>{{ usage.type }}</small></td>
                                        <td>
                                          <v-btn color="primary" @click="openResourceById('ENTITY', usage.entityId)">Open</v-btn>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDeleteModal">Close</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                        </div>
                      </div></v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <v-btn
                        outlined
                        :disabled="!isFormValid || !isEdit"
                        color="primary"
                        class="mr-1 color-accent-text"
                        @click="copyCreateResource(entity, 'entityCreate', $router, '', $options.filters.formatDateTime(new Date()), false, true)"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-content-copy
                        </v-icon>
                        {{ $lang.actions.copyCreate }}
                      </v-btn>
                      <v-btn
                        :disabled="!isFormValid || entity.fields.length < 1"
                        color="primary"
                        :loading="loading"
                        class="ml-2 color-white"
                        data-cy="entity-submit"
                        @click="submit()"
                      >
                        <v-icon class="mr-1">mdi-content-save-outline</v-icon>
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="fill-height custom-bg">
                <div style="width: 100%; height: 100%">
                  <v-row no-gutters class="mt-2 align-center">
                    <v-col cols="12" md="9">
                      <v-select
                        v-model="entity.priority"
                        :items="priorities"
                        :label="$lang.labels.priority"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        data-cy="cron-priority"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title :data-cy="`${item} priority`">
                              {{ item }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <!-- All inputs in this row are repeated in other components (quick solution), needs a small refactoring -->
                    <v-col cols="12" md="9">
                      <v-text-field
                        v-model="entity.simultaneousExecutionsPerInstance"
                        outlined
                        dense
                        :label="$lang.labels.simultaneousExecutionsPerInstance"
                        :rules="[
                          (v) => !!v || $lang.errors.required,
                          (v) =>
                            v >= 1 ||
                            'Must be greater than 0',
                          (v) =>
                            Number(v) <=
                            Number(entity.overallSimultaneousExecutions) ||
                            'Must be less than or equal to overall simultaneous executions',
                        ]"
                        :readonly="!userCanEdit"
                        data-cy="simultaneous-executions-per-instance-resources-generation"
                        class="required-asterisk"
                        required
                        type="number"
                        @keypress="filterNumber($event)"
                      />
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-text-field
                        v-model="entity.overallSimultaneousExecutions"
                        outlined
                        dense
                        :label="
                          $lang.labels
                            .overallSimultaneousExecutions
                        "
                        :rules="[
                          (v) => !!v || $lang.errors.required,
                          (v) =>
                            v >= 1 ||
                            'Must be greater than 0',
                        ]"
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                        required
                        data-cy="overall-simultaneous-executions-resources-generation"
                        type="number"
                        @change="
                          $refs.form.validate()
                        "
                        @keypress="filterNumber($event)"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="d-flex align-center justify-space-between">
                    <!-- Basic Auth Checkbox aligned at the start -->
                    <v-col cols="6" md="4">
                      <v-checkbox
                        v-model="entity.basicAccessAuthentication"
                        data-cy="allow-basic-auth-resources-generation"
                        :label="$lang.labels.basicAccessAuthentication"
                        hide-details
                      />
                      <v-checkbox
                        v-model="entity.isGdprRelevant"
                        :label="$lang.labels.gdpr"
                        data-cy="entity-gdpr"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <GenerationCard
                :entity-id="entity.id"
                :valid="valid"
                :user-can-edit="userCanEdit"
                :is-edit="isEdit"
                :is-dirty="isDirty"
                :lock="lock"
                :user-can-delete="userCanDelete"
                :simple-error="simpleError"
                :success-persistence-messages-array="successPersistenceMessagesArray"
                :success-resources-messages-array="successResourcesMessagesArray"
                :trigger-force-logic-persistence="triggerForceLogicPersistence"
                :trigger-force-logic-resources="triggerForceLogicResources"
                :currently-open-delete-action="currentlyOpenDeleteAction"
                :delete-success="deleteSuccess"
                @deletePersistenceFunct="deletePersistenceFunct"
                @deleteResourcesFunct="deleteResourcesFunct"
                @resetValidateGenerateDelete="resetValidateGenerateDelete"
              />
            </v-tab-item>
            <v-tab-item
              :key="3"
              class="fill-height "
            >
              <v-card class="pa-2 fill-height custom-bg" >
                <v-row wrap no-gutters class="pb-4">
                  <v-col
                    cols="12"
                    class="mt-4"
                  >
                    <Table
                      class="pa-0 ma-0  "
                      :items="entity.usages"
                      type="parent"
                    ></Table>
                  </v-col>
                  <v-col
                    cols="12"
                    class="mt-4"
                  >
                    <h4 class="color-primary">{{ $lang.labels.childRelations }}</h4>
                    <v-data-table
                      v-if="childEntities && childEntities.length > 0"
                      :headers="headerChildResources"
                      :items="childEntities"
                      item-key="entity.id"
                      class="elevation-0 background-transparent pa-0"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                      <template v-slot:item.field.relation.type="{ item }">
                        <div>{{ $lang.status[item.field.relation.type] }}</div>
                      </template>

                      <template v-slot:item.actions="{ item }">
                        <span v-if="item.field.relation.id" class="color-info clickable-simple" @click="openResourceById('ENTITY', item.field.relation.id)">Open</span>
                      </template>
                    </v-data-table>
                    <v-alert
                      v-else
                      type="info"
                      class="mt-4"
                    >
                      {{ $lang.hints.noChildRelationsToShow }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="4"
              class="fill-height"
            >
              <v-card class="py-1 fill-height custom-bg">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters style="width: 100%; height: 100%">
                    <v-col cols="12">
                      <entity-diagram
                        :id="entity.id"
                        :name="entity.name"
                        :fields="entity.fields"
                        :usages="entity.usages"
                        :child-entities="childEntities"
                        :user-can-edit="userCanEdit"
                        :is-dirty="isDirty"
                        :is-edit="isEdit"
                        :lock="lock"
                        :user-can-delete="userCanDelete"
                        :is-super-user="isSuperUser"
                        :simple-error="simpleError"
                        :currently-open-delete-action="currentlyOpenDeleteAction"
                        :delete-success="deleteSuccess"
                        :success-persistence-messages-array="successPersistenceMessagesArray"
                        :success-resources-messages-array="successResourcesMessagesArray"
                        :trigger-force-logic-persistence="triggerForceLogicPersistence"
                        :trigger-force-logic-resources="triggerForceLogicResources"
                        @deletePersistenceFunct="deletePersistenceFunct"
                        @deleteResourcesFunct="deleteResourcesFunct"
                        @resetValidateGenerateDelete="resetValidateGenerateDelete"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="5"
              class="fill-height "
            >
              <v-card class="pa-2 fill-height custom-bg" >
                <v-row wrap no-gutters class="pb-4">
                  <v-col
                    cols="12"
                    class="mt-4"
                  >
                    <v-data-table
                      v-if="formattedConnectedResourcesData && formattedConnectedResourcesData.length > 0"
                      :headers="headerConnectedResources"
                      :items="formattedConnectedResourcesData"
                      item-key="resourceId"
                      class="elevation-0 background-transparent pa-0"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                      <template v-slot:item.resourceType="{ item }">
                        <div>{{ $lang.status[item.resourceType] }}</div>
                      </template>

                      <template v-slot:item.resourceActionGenerated="{ item }">
                        <div>{{ $lang.status[item.resourceActionGenerated] }}</div>
                      </template>

                      <template v-slot:item.actions="{ item }">
                        <span v-if="item.resourceId" class="color-info clickable-simple" @click="openResourceById(item.resourceType, item.resourceId)">Open</span>
                      </template>
                    </v-data-table>
                    <v-alert
                      v-else
                      type="info"
                      class="mt-4"
                    >
                      {{ $lang.hints.noConnectedResourcesToShow }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="6"
              class="fill-height "
            >
              <v-card class="pa-2 custom-bg" style="min-height: 100%;">
                <v-row wrap no-gutters class="pb-2">
                  <h2>Resource roles</h2>
                </v-row>
                <v-row wrap no-gutters>
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
                <v-row wrap no-gutters class="pb-2">
                  <v-divider />
                </v-row>
                <v-row wrap no-gutters class="pb-2">
                  <h2>System Data roles</h2>
                </v-row>
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.READ }}</h3>
                      <user-roles-select
                        :role="readRolesIds"
                        :options="allRoles"
                        data-cy="roles-read"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="readRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.CREATE }}</h3>
                      <user-roles-select
                        :role="createRolesIds"
                        :options="allRoles"
                        data-cy="roles-create"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="createRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.UPDATE }}</h3>
                      <user-roles-select
                        :role="updateRolesIds"
                        :options="allRoles"
                        data-cy="roles-update"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="updateRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.DELETE }}</h3>
                      <user-roles-select
                        :role="deleteRolesIds"
                        :options="allRoles"
                        data-cy="roles-delete"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="deleteRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="7"
              class="fill-height"
            >
              <v-card class="py-1 fill-height custom-bg">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <history-table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreEntity($event)"
                      ></history-table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <multi-errors-snackbar
      :show="showSnackErrors"
      :errors="errorsForSnackbar"
      @close="showSnackErrors = false; errorsForSnackbar = []"
    />
  </v-container>
</template>

<script>
import Table from '@/components/ui/datatables/FlowyEntitiesRelationsDatatable.vue'
import HistoryTable from '@/components/ui/datatables/FlowyHistoryDatatable'
import EntityDiagram from '../../components/ui/EntityDiagram.vue'
import {
  createEntityUsingPOST as createEntity,
  deleteEntityUsingDELETE as deleteEntity,
  disableEntityUsingGET as disableEntity,
  enableEntityUsingGET as enableEntity,
  getEntityByIdUsingGET as getEntity,
  getHistoriesUsingGET as getHistories,
  getProcessCredentialByIdUsingGET as getCredential,
  getProcessCredentialsUsingGET as getCredentials,
  updateEntityUsingPUT as updateEntity,
  entityPersistenceDeleteUsingDELETE as deletePersistence,
  deleteEntityResourcesUsingDELETE as deleteResources,
  getEntityResourcesUsingGET as getResources
} from '@/utils/api'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import MultiErrorsSnackbar from '@/components/ui/MultiErrorsSnackbar.vue'
import copy from 'copy-to-clipboard'
import EntityField from './EntityField'
import GenerationCard from '../../components/ui/modals/GenerationCard.vue'
import EntityDeleteOptions from './EntityDeleteOptions.vue'
import { mapActions } from 'vuex'
import { copyCreateResource, getRolesWithoutAuth, filterNumber } from '@/utils/helpers'
import { bus } from '@/main'
import { resourcesTypes } from '@/utils/constants'
import { castDefaultValueFromDataType } from '@/utils/helpers'

export default {
  components: {
    ActionButtonWithConfirmation,
    MultiErrorsSnackbar,
    Table,
    UserRolesSelect,
    EntityField,
    HistoryTable,
    EntityDiagram,
    GenerationCard
  },
  data() {
    return {
      isDirty: false,
      tempKey: 0,
      tab: 0,
      valid: false,
      isEdit: false,
      loading: true,
      lock: true,
      entity: {
        createdOn: '',
        id: '',
        modifiedOn: '',
        name: '',
        status: '',
        fields: [],
        roles: [],
        systemPermissionRoles: [],
        isGdprRelevant: false,
        credentialId: '',
        usages: [],
        basicAccessAuthentication: false,
        overallSimultaneousExecutions: 32,
        simultaneousExecutionsPerInstance: 32,
        priority: 64
      },
      newField: {
        name: '',
        dataType: 'VARCHAR',
        searchType: 'NO',
        relation: {
          id: 0,
          type: 'ONE_TO_ONE',
          entityRequest: null
        },
        primaryKey: false,
        required: true,
        unique: false,
        sortable: false,
        defaultValue: null
      },
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      readRolesIds: [],
      createRolesIds: [],
      updateRolesIds: [],
      deleteRolesIds: [],
      userRolesIds: [],
      systemPermissionRolesIds: [],
      userCanEdit: false,
      isPrimaryKey: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      browseDataAccess: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      successPersistenceMessagesArray: [],
      deleteSuccess: false,
      file: null,
      fileData: null,
      isLoadingCredentials: false,
      credentials: [],
      searchCredentials: '',
      resources: [],
      resourcesTypes,
      simpleError: '',
      errorsForSnackbar: [],
      showSnackErrors: false,
      successResourcesMessagesArray: [],
      currentlyOpenDeleteAction: 0,
      connectedResources: [],
      headerConnectedResources: [
        { text: this.$lang.labels.name, value: 'resourceName', align: 'start', sortable: true },
        { text: this.$lang.labels.resourceAction, value: 'resourceActionGenerated', sortable: true },
        { text: this.$lang.labels.type, value: 'resourceType' },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      childEntities: [],
      headerChildResources: [
        { text: this.$lang.labels.entity, value: 'entity.name', align: 'start', sortable: true },
        { text: this.$lang.labels.field, value: 'field.name' },
        { text: this.$lang.labels.type, value: 'field.relation.type' },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      entityType: 'JDBC',
      entityTypes: ['JDBC', 'MONGODB'],
      triggerForceLogicResources: false,
      triggerForceLogicPersistence: false,
      deleteOptions: {
        isPersistenceDeleteChecked: false,
        isResourcesDeleteChecked: false
      },
      isDeleteDialogLoading: false,
      noErrorsInAdditionalDeletions: true,
      deleteModal: false,
      entityUsageNames: [],
      duplicateNames: []
    }
  },
  computed: {
    formattedEntityTypes() {
      return this.entityTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    },
    formattedConnectedResourcesData() {
      if (this.connectedResources && this.connectedResources.length > 0) {
        return this.connectedResources.map((item) => {
          return {
            ...item,
            resourceActionGenerated: this.findResourceActionFromName(item.resourceName ? item.resourceName : '')
          }
        })
      }

      return []
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.entity.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.entity.modifiedOn)}, ID: ${this.entity.id}`
    },
    entityDeleteOptionsConfig() {
      return {
        component: EntityDeleteOptions,
        props: { entityId: this.entity.id }
      }
    },
    isFormValid() {
      return this.valid && !this.lock && this.userCanEdit
    },
    priorities() {
      return Array.from({ length: 128 }, (_, index) => index + 1)
    }
  },
  watch: {
    isFormValid(val) {
      this.formValid(val && this.entity.fields.length > 0)
    },
    'entity.fields': {
      handler(fields) {
        this.checkDuplicateFieldNames(fields)
      },
      deep: true,
      immediate: true
    },
    tab: {
      handler(val) {
        this.connectedResources = []
        this.history = { items: [], meta: {} }
        if (val === 7) {
          this.fetchHistory()
        } else if (val === 3) {
          this.extractChildRelations()
        } else if (val === 5) {
          this.getConnectedResources()
        }
      }
    },
    entity: {
      async handler(val) {
        if (!this.lock) this.isDirty = true
      },
      deep: true
    },
    searchCredentials: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchCredentialsFunction(val)
        }
      }
    },
    entityType: {
      handler(val) {
        if (!this.lock) {
          this.entity.credentialId = ''
          this.searchCredentials = ''
          this.credentials = []
          if (val === 'MONGODB') {
            this.entity.fields.forEach((field) => {
              field.primaryKey = null
            })
          } else {
            this.entity.fields.forEach((field, i) => {
              if (i === 0) {
                field.primaryKey = true
              } else {
                field.primaryKey = false
              }
            })
          }
        }
      }
    },
    deleteOptions: {
      handler() {
        this.simpleError = ''
      },
      deep: true
    }
  },
  mounted() {
    bus.$on('saveResource', this.submit)
  },
  beforeDestroy() {
    bus.$off('saveResource', this.submit)
  },
  created() {
    this.createdFunction()
  },
  methods: {
    ...mapActions('app', ['addSnackbar', 'formValid']),
    filterNumber,
    copyCreateResource,
    checkDuplicateFieldNames(fields) {
      const nameCounts = fields.reduce((acc, field) => {
        acc[field.name] = (acc[field.name] || 0) + 1

        return acc
      }, {})

      const duplicates = Object.keys(nameCounts).filter((name) => nameCounts[name] > 1)

      this.$nextTick(() => {
        this.$set(this, 'duplicateNames', duplicates)
        if (duplicates.length > 0) {
          this.addSnackbar({
            message: `Duplicate field names found: ${duplicates.join(', ')}`,
            timeout: 5000,
            color: 'error'
          })
        }
      })
    },
    moveField(index, direction) {
      const element = this.entity.fields[index]

      this.entity.fields.splice(index, 1)
      this.entity.fields.splice(
        direction === 'down' ? index + 1 : index - 1,
        0,
        element
      )
      this.tempKey++
    },
    findResourceActionFromName(name) {
      if (name.includes('CREATE')) {
        return 'CREATE'
      } else if (name.includes('UPDATE')) {
        return 'UPDATE'
      } else if (name.includes('DELETE')) {
        return 'DELETE'
      } else if (name.includes('FIND_BY_ID')) {
        return 'FIND_BY_ID'
      } else if (name.includes('SEARCH')) {
        return 'SEARCH'
      } else if (name.includes('validation') && name.includes('BODY')) {
        return 'VALIDATION_BODY'
      } else if (name.includes('validation') && name.includes('ID')) {
        return 'VALIDATION_PATH'
      }

      return ''
    },
    createdFunction() {
      this.lock = true
      this.isEdit = this.$route.name === 'entityEdit'

      let user = null

      if (localStorage.userData) {
        user = JSON.parse(localStorage.userData)

        this.browseDataAccess = false
        this.userRolesIds = user.roles.map((x) => x.id)
        this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
        this.userCanDelete = !!user.roles.find((x) => x.name === 'ENTITY_DELETER') || this.isSuperUser
      }

      this.init()
        .then(() => {
          if (this.isEdit && this.$route.params.id) {
            this.loading = true
            getEntity({ id: this.$route.params.id })
              .then((res) => {
                this.entity = res.data.data

                this.editRolesIds = this.entity.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
                this.useRolesIds = this.entity.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
                this.viewRolesIds = this.entity.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)
                if (!this.entity.systemPermissionRoles) this.entity.systemPermissionRoles = []
                this.readRolesIds = this.entity.systemPermissionRoles.filter((x) => x.permissionType === 'READ').map((y) => y.roleId)
                this.createRolesIds = this.entity.systemPermissionRoles.filter((x) => x.permissionType === 'CREATE').map((y) => y.roleId)
                this.updateRolesIds = this.entity.systemPermissionRoles.filter((x) => x.permissionType === 'UPDATE').map((y) => y.roleId)
                this.deleteRolesIds = this.entity.systemPermissionRoles.filter((x) => x.permissionType === 'DELETE').map((y) => y.roleId)

                this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

                this.browseDataAccess = this.isSuperUser || !!this.readRolesIds.find((x) => this.userRolesIds.includes(x))

                if (this.entity.credentialId) {
                  getCredential({ id: this.entity.credentialId })
                    .then((res) => {
                      if (res.status !== 200) {
                        if (res.response.status === 403) {

                          this.addSnackbar({
                            message: this.$lang.errors.noPermission,
                            timeout: 5000,
                            color: 'error'
                          })
                          this.userCanEdit = false
                        }
                        this.loading = false
                        setTimeout(() => this.lock = false, 100)

                        return
                      }

                      this.searchCredentials = res.data.data.name
                      this.credentials = [res.data.data]
                      const selectedCredential = this.credentials.find((x) => x.id === this.entity.credentialId)

                      if (selectedCredential) {
                        this.entityType = selectedCredential.type
                      }
                      this.loading = false
                      this.isDirty = false
                      setTimeout(() => this.lock = false, 100)
                      setTimeout(() => this.$refs.form.validate(), 150)
                    })
                    .catch((err) => {
                      this.addSnackbar({
                        message: err,
                        timeout: 5000,
                        color: 'error'
                      })
                      this.loading = false
                      setTimeout(() => this.lock = false, 100)
                      setTimeout(() => this.$refs.form.validate(), 150)
                      this.isDirty = false
                    })
                } else {
                  this.loading = false
                  setTimeout(() => this.lock = false, 100)
                  setTimeout(() => this.$refs.form.validate(), 150)
                  this.isDirty = false
                }
              })
              .catch((err) => {
                this.addSnackbar({
                  message: err,
                  timeout: 5000,
                  color: 'error'
                })
                this.loading = false
                setTimeout(() => this.lock = false, 100)
                setTimeout(() => this.$refs.form.validate(), 150)
                this.isDirty = false
              })
          } else if (this.$route.params.copy) {
            this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
            this.userCanEdit = true
            if (this.$route.params.copy.id) {
              getEntity({ id: this.$route.params.copy.id })
                .then((res) => {
                  this.entity = res.data.data
                  this.entity.id = null
                  this.entity.name = `${this.entity.name} - Copy - ${this.$options.filters.formatDateTime(new Date())}`
                  if (this.entity.credentialId) {
                    getCredential({ id: this.entity.credentialId })
                      .then((res) => {
                        this.searchCredentials = res.data.data.name
                        this.credentials = [res.data.data]
                        const selectedCredential = this.credentials.find((x) => x.id === this.entity.credentialId)

                        if (selectedCredential) {
                          this.entityType = selectedCredential.type
                        }
                        this.loading = false
                        setTimeout(() => this.lock = false, 100)
                        this.isDirty = false
                      })
                      .catch((err) => {
                        this.addSnackbar({
                          message: err,
                          timeout: 5000,
                          color: 'error'
                        })
                        this.loading = false
                        setTimeout(() => this.lock = false, 100)
                        this.isDirty = false
                      })
                  } else {
                    this.loading = false
                    setTimeout(() => this.lock = false, 100)
                    this.isDirty = false
                  }
                })
                .catch((err) => {
                  this.addSnackbar({
                    message: err,
                    timeout: 5000,
                    color: 'error'
                  })
                  this.loading = false
                  setTimeout(() => this.lock = false, 100)
                  this.isDirty = false
                })
            } else {
              this.loading = false
              setTimeout(() => this.lock = false, 100)
              this.isDirty = false
            }
          } else if (this.$route.params.restore) {
            this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
            this.userCanEdit = true
            this.entity = this.$route.params.restore
            this.entity.id = 0
            if (this.entity.credentialId) {
              getCredential({ id: this.entity.credentialId })
                .then((res) => {
                  this.searchCredentials = res.data.data.name
                  this.credentials = [res.data.data]
                  const selectedCredential = this.credentials.find((x) => x.id === this.entity.credentialId)

                  if (selectedCredential) {
                    this.entityType = selectedCredential.type
                  }
                  this.loading = false
                  setTimeout(() => this.lock = false, 100)
                  setTimeout(() => this.$refs.form.validate(), 150)
                  this.isDirty = false
                })
                .catch((err) => {
                  this.addSnackbar({
                    message: err,
                    timeout: 5000,
                    color: 'error'
                  })
                  this.loading = false
                  setTimeout(() => this.lock = false, 100)
                  setTimeout(() => this.$refs.form.validate(), 150)
                  this.isDirty = false
                })
            } else {
              this.loading = false
              setTimeout(() => this.lock = false, 100)
              setTimeout(() => this.$refs.form.validate(), 150)
              this.isDirty = false
            }
          } else {
            this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
            this.userCanEdit = true
            this.loading = false
            setTimeout(() => this.lock = false, 100)
            this.isDirty = false
          }
        })
    },
    findEntityById(id) {
      return new Promise((resolve, reject) => {
        getEntity({ id })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    extractChildRelations() {
      const fieldRelations = this.entity.fields ? this.entity.fields.filter((x) => x.relation && x.relation.id) : []

      const fetchedEntities = fieldRelations.map(async (x) => {
        return {
          field: x,
          entity: await this.findEntityById(x.relation.id)
        }
      })

      Promise.all(fetchedEntities).then((res) => {
        this.childEntities = res
      })
    },
    getConnectedResources() {
      getResources({ id: this.entity.id })
        .then((res) => {
          this.connectedResources = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    openResource(resourceTypeObject) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/${this.resourcesTypes[resourceTypeObject.resource.resourceType]}/${resourceTypeObject.resource[resourceTypeObject.type].id}`, '_blank')
    },
    openResourceById(resourceType, resourceId) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/${this.resourcesTypes[resourceType]}/${resourceId}`, '_blank')
    },
    openCredential() {
      if (!this.entity.credentialId) return

      window.open(`/${localStorage.selectedLanguage || 'en'}/credentials/edit/${this.entity.credentialId}`, '_blank')
    },
    updateEntityField(index, data) {
      this.entity.fields[index] = data
      if (data.primaryKey) {
        this.entity.fields.forEach((x, i) => {
          if (i !== index) {
            x.primaryKey = false
          }
        })
      }
    },
    deleteField(index) {
      const tempFields = this.entity.fields.filter((x, i) => i !== index)

      this.entity.fields = structuredClone(tempFields)

      this.tempKey++
    },
    addNewField() {
      this.entity.fields.push(structuredClone(this.newField))
    },
    searchCredentialsFunction(val = '') {

      if (!val || val.length < 2) return

      this.isLoadingCredentials = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.type = this.entityType

      getCredentials(obj)
        .then((res) => {
          if (res.status !== 200) {
            if (res.response.status === 403) {
              this.addSnackbar({
                message:  this.$lang.errors.noPermission,
                timeout: 5000,
                color: 'error'
              })
              this.userCanEdit = false
            }
            this.isLoadingCredentials = false
            this.userCanEdit = false

            return
          }
          this.credentials = res.data.data.items
          this.isLoadingCredentials = false
        })
        .catch((err) => {
          this.isLoadingCredentials = false
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    copyName() {
      if (this.entity.name) {
        copy(this.entity.name)
        this.addSnackbar({
          message:  this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {
        this.addSnackbar({
          message:  this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    copyCredential() {
      const credential = this.credentials.find((x) => x.id === this.entity.credentialId)

      if (credential) {
        copy(credential.name)

        this.addSnackbar({
          message: this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {

        this.addSnackbar({
          message:  this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    async deletePersistenceFunct(id = this.entity.id, fromEntityDelete = false) {
      if (!fromEntityDelete) {
        this.currentlyOpenDeleteAction = 2
      }
      this.triggerForceLogicPersistence = false
      this.successPersistenceMessagesArray = []
      this.deleteSuccess = false
      this.simpleError = ''
      try {
        const res = await deletePersistence({ id })

        if (res.status === 200) {
          this.successPersistenceMessagesArray = res.data.data
        } else {
          this.simpleError = res.response.data.statusText
          this.triggerForceLogicPersistence = true
          if (fromEntityDelete) {
            this.noErrorsInAdditionalDeletions = false
          }
          throw new Error(res.response.data.statusText)
        }
      } catch (error) {
        this.simpleError = error.message
        if (fromEntityDelete) {
          this.noErrorsInAdditionalDeletions = false
        }
        throw error
      }
    },
    async deleteResourcesFunct(isForced = false, id = this.entity.id, fromEntityDelete = false) {
      if (!fromEntityDelete) {
        this.currentlyOpenDeleteAction = 3
      }
      this.triggerForceLogicResources = false
      this.successResourcesMessagesArray = []
      this.deleteSuccess = false
      this.simpleError = ''
      try {
        const res = await deleteResources({ id, force: isForced })

        if (res.status === 200) {
          this.deleteSuccess = true
          this.successResourcesMessagesArray = res.data.data
        } else {
          this.triggerForceLogicResources = true
          this.successResourcesMessagesArray = res.response.data.data
          this.simpleError = res.response.data.statusText
          if (fromEntityDelete) {
            this.simpleError = this.simpleError + '. Please try to choose other options.'
            this.noErrorsInAdditionalDeletions = false
          }
        }
      } catch (error) {
        this.simpleError = error.message
        if (fromEntityDelete) {
          this.simpleError = this.simpleError + '. Please try to choose other options.'
          this.noErrorsInAdditionalDeletions = false
        }
        throw error
      }
    },
    closeDeleteModal() {
      this.deleteModal = false
    },
    async handleEntityUsages() {
      const usagePromises = this.entity.usages.map(async (usage) => {
        try {
          const entityDetails = await this.findEntityById(usage.entityId)

          if (entityDetails && entityDetails.name) {
            return entityDetails.name
          }
        } catch (err) {
          console.error(`Failed to fetch entity details for ID ${usage.entityId}:`, err)
        }

        return null
      })

      this.entityUsageNames = (await Promise.all(usagePromises)).filter((name) => name)
    },
    async deleteEntityFunct(isForced = false) {

      if (this.entity.usages.length > 0) {
        await this.handleEntityUsages()
        this.deleteModal = true
        this.addSnackbar({
          message: this.$lang.errors.entityCantBeDeleted,
          timeout: 5000,
          color: 'error'
        })
        this.deleteSuccess = false
        this.isDeleteDialogLoading = false

        return
      }

      this.currentlyOpenDeleteAction = 1
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      this.isDeleteDialogLoading = true
      this.noErrorsInAdditionalDeletions = true

      try {
        await this.performAdditionalDeletions(isForced)
      } catch (err) {
        if (this.simpleError) {
          this.simpleError = this.simpleError + '. Please try to choose other options.'
        }
        this.isDeleteDialogLoading = false
        this.noErrorsInAdditionalDeletions = false

        return
      }

      if (this.noErrorsInAdditionalDeletions) {
        try {
          const entityDeleteRes = await deleteEntity({ id: this.entity.id, force: isForced })

          if (entityDeleteRes.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = entityDeleteRes.data.data.usages
          } else {
            this.deleteSuccess = false

            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = entityDeleteRes.response.data.data.usages
          }

          this.isDeleteDialogLoading = false
        } catch (err) {
          this.simpleError = err
          this.deleteSuccess = false

          if (this.simpleError) {
            this.simpleError = this.simpleError + '. Please try to choose other options.'
          }
          this.isDeleteDialogLoading = false
        }
      } else {
        this.deleteSuccess = false

        this.isDeleteDialogLoading = false

        return
      }
    },
    async performAdditionalDeletions(isForced = false) {
      if (this.deleteOptions.isResourcesDeleteChecked) {
        await this.deleteResourcesFunct(isForced, this.entity.id, true)
      }
      if (this.deleteOptions.isPersistenceDeleteChecked) {
        await this.deletePersistenceFunct(this.entity.id, true)
      }
    },
    resetValidateGenerateDelete() {
      this.triggerForceLogic = false
      this.triggerForceLogicResources = false
      this.triggerForceLogicPersistence = false
      this.regularDeleteErrorsUsages = []
      this.successPersistenceMessagesArray = []
      this.successResourcesMessagesArray = []
      this.simpleError = ''
      this.deleteSuccess = false
    },
    fetchHistory(options) {
      if (!this.entity.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.entity.id
      obj.resourceType = 'ENTITY'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    restoreEntity(data) {
      this.entity = data

      if (this.entity && this.entity.roles) {
        this.editRolesIds = this.entity.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.entity.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.entity.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }
    },
    init() {
      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async submit() {
      this.err = ''
      this.showSnackErrors = false
      this.errorsForSnackbar = []

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {

        this.addSnackbar({
          message: this.$lang.errors.editRoleCreate,
          timeout: 5000,
          color: 'error'
        })
      }

      this.lock = true
      this.loading = true

      let res = null

      const localEntity = structuredClone(this.entity)

      const { id } = localEntity

      if (!this.isEdit) {
        delete localEntity.id
        localEntity.status = 'ACTIVE'
      }
      delete localEntity.createdOn
      delete localEntity.modifiedOn

      const startsWithNumber = /^\d/

      localEntity.name = localEntity.name.trim().toLowerCase()

      if (startsWithNumber.test(localEntity.name)) {

        this.addSnackbar({
          message: this.$lang.errors.entityNameCantStartWithNumber,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
        this.loading = false

        return
      }

      const entityNamesStartWithNumber = []

      localEntity.fields.forEach((x) => {
        x.name = x.name.trim().toLowerCase()

        if (startsWithNumber.test(x.name)) {
          entityNamesStartWithNumber.push(x.name)
        }

        x.defaultValue = castDefaultValueFromDataType(x.defaultValue, x.dataType)
        // console.log(`Data Type: ${x.dataType}, Casted Value:`, x.defaultValue, 'Type:', typeof x.defaultValue)
      })

      if (entityNamesStartWithNumber.length > 0) {
        this.addSnackbar({
          message: `${this.$lang.errors.entityFieldNamesCantStartWithNumber}: ${entityNamesStartWithNumber.join(', ')}`,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
        this.loading = false

        return
      }

      if (this.entityType === 'JDBC') {
        const isOneFieldPrimaryKey = localEntity.fields.filter((x) => x.primaryKey).length === 1

        if (!isOneFieldPrimaryKey) {

          this.addSnackbar({
            message: this.$lang.errors.onePrimaryKey,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false
          this.loading = false

          return
        }

        const primaryKeyField = localEntity.fields.find((x) => x.primaryKey)

        if (primaryKeyField.dataType !== 'VARCHAR') {
          if (!localEntity.fields.some((field) => !field.primaryKey)) {

            this.addSnackbar({
              message: this.$lang.errors.entityMustContainNonPrimaryField,
              timeout: 5000,
              color: 'error'
            })
            this.lock = false
            this.loading = false

            return
          }
        }
      }

      if (this.entityType === 'MONGODB') {
        const isOneFieldNameId = localEntity.fields.filter((x) => x.name === '_id').length > 0

        if (isOneFieldNameId) {
          this.addSnackbar({
            message: this.$lang.errors.fieldCantHaveIdName,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false
          this.loading = false

          return
        }
      }

      const tempRoles = []
      const tempSystemRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      // System Role handling
      this.readRolesIds.forEach((x) => {
        tempSystemRoles.push({ permissionType: 'READ', roleId: x })
      })

      this.createRolesIds.forEach((x) => {
        tempSystemRoles.push({ permissionType: 'CREATE', roleId: x })
      })

      this.updateRolesIds.forEach((x) => {
        tempSystemRoles.push({ permissionType: 'UPDATE', roleId: x })
      })

      this.deleteRolesIds.forEach((x) => {
        tempSystemRoles.push({ permissionType: 'DELETE', roleId: x })
      })

      localEntity.roles = tempRoles
      localEntity.systemPermissionRoles = tempSystemRoles

      localEntity.fields.forEach((x) => {
        if (x.relation && x.relation.id === 0) {
          x.relation = null
        }
      })

      try {

        res = this.isEdit ? await updateEntity({ id, body: localEntity }) : await createEntity({ body: localEntity })

        if (res && res.status !== 200) {
          if (res?.response?.data?.data?.[0]?.error) {

            res?.response?.data?.data?.forEach((error) => {
              this.errorsForSnackbar.push({
                text: error.error,
                value: null
              })
            })
            this.showSnackErrors = true
          } else {

            const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.entityUpdate : this.$lang.errors.entityCreate)

            this.addSnackbar({
              message: errorMessage,
              timeout: 5000,
              color: 'error'
            })
            if (res.response.status === 403) {
              this.addSnackbar({
                message: this.$lang.errors.noPermission,
                timeout: 5000,
                color: 'error'
              })
              this.userCanEdit = false
            }
          }
          this.lock = false
          this.loading = false

          return
        }

        const successMessage = this.isEdit ? this.$lang.success.entityUpdated : this.$lang.success.entityCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        if (!this.isEdit) {
          this.isEdit = true
          this.$router.push({
            name: 'entityEdit',
            params: {
              id: res.data.data.id
            }
          })
          this.lock = false
        } else {
          this.createdFunction()
        }

        this.loading = false
        this.isDirty = false

      } catch (err) {
        this.lock = false
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    async changeStatus () {
      this.lock = true
      try {
        const res = this.entity.status === 'ACTIVE' ? await disableEntity({ id: this.entity.id }) : await enableEntity({ id: this.entity.id })

        if (res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.entityStatusUpdate,
            timeout: 5000,
            color: 'error'
          })
          if (res.response.status === 403) {
            this.addSnackbar({
              message: this.$lang.errors.noPermission,
              timeout: 5000,
              color: 'error'
            })
            this.userCanEdit = false
          }
          this.lock = false
        }

        const successMessage = this.entity.status !== 'ACTIVE' ? this.$lang.success.entityActivated : this.$lang.success.entityDeactivated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })

        this.entity.status = res.data.data.status

        this.lock = false

        setTimeout(() => this.isDirty = false, 50)

      } catch (err) {
        this.lock = false
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    passDeleteOptions(data) {
      this.deleteOptions = data
    }
  }
}
</script>

<style>
.copy-name-icon .v-icon{
  color: #009fb7;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
</style>
